$(document).ready(function(){
    $('#tickets-list').DataTable({
      "columnDefs": [
            {
                "targets": [ 0 ],
                "visible": false,
                "searchable": false

            },

            {
           "type": "datetime-moment", //use moment.js to calulate and sort date col
           "targets": [ 5 ]
   }
        ],
      "order": [[ 0, "asc" ], [ 5, "asc" ]],
        pageLength: 25,
        responsive: true,
        dom: 'Bfrtip',
        dom: "<'row dom_wrapper fh-fixedHeader'<'col-sm-12 col-md-6'B><'col-sm-12 col-md-6'f>>" +
"<'row'<'col-sm-12'tr>>" +
"<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [


            {extend: 'copyHtml5', className: 'btn-primary'},
            {extend: 'csvHtml5', className: 'btn-primary'},
            {extend: 'excelHtml5', title: 'ExampleFile', className: 'btn-primary'},
            {extend: 'pdfHtml5', title: 'ExampleFile', className: 'btn-primary'},

            {extend: 'print', className: 'btn-primary',
             customize: function (win){
                    $(win.document.body).addClass('white-bg');
                    $(win.document.body).css('font-size', '10px');

                    $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
            }
            }
        ],
        header: true,
    headerOffset: $('#fixed').height()

    });

});
