// Bootstrap Datepicker

// Flatpickr
$(function () {
  // Date
  $('#flatpickr-date').flatpickr({
    altInput: true
  });

  $('#flatpickr-date2').flatpickr({
    altInput: true
  });

  $('#flatpickr-date3').flatpickr({
    altInput: true
  });

  // Time
  $('#flatpickr-time').flatpickr({
    enableTime: true,
    noCalendar: true,
    altInput: true
  });

  // Datetime
  $('#flatpickr-datetime').flatpickr({
    enableTime: true,
    altInput: true
  });

  // Full
  $('#flatpickr-full').flatpickr({
    weekNumbers: true,
    enableTime: true,
    mode: 'multiple',
    minDate: 'today',
    altInput: true
  });

  // Range
  $('#flatpickr-range').flatpickr({
    mode: 'range',
    altInput: true
  });

  // Inline
  $('#flatpickr-inline').flatpickr({
    inline: true,
    altInput: true,
    allowInput: false
  });
});
